import{ 
    Home,
}from '~/pages';
import config from '~/config';

// Public routes
const publicRoutes = [
   { path: config.routes.home, component: Home },
 ];

// private những page cần đăng nhập mới hiển thị
const privateRoutes = [];

export { publicRoutes, privateRoutes };


