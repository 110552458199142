import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faLocationDot, faEnvelope, faPhone, faHouseSignal } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames/bind';
import images from '~/assets';
import styles from './Home.module.scss';
import { Link } from 'react-router-dom';
import config from '~/config';
import { CustomSlider, CustomSlider2, CustomSlider3, CustomSlider4 } from '~/layouts/components';

const cx = classNames.bind(styles);

function Home() {
    const [activeSider, setActiveSider] = useState(1);
    const [showHeader, setShowHeader] = useState(false);
    const [previousSider, setPreviousSider] = useState(1);
    const siderRefs = useRef([]);

    const handleDragStart = (e) => {
        e.preventDefault();
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
    };

    const handleDotVisibility = () => {
        const dots = document.querySelectorAll('.dot');

        dots.forEach((dot, index) => {
            const siderRect = siderRefs.current[index].getBoundingClientRect();

            if (siderRect.top >= 0 && siderRect.bottom <= window.innerHeight) {
                dot.style.display = 'block';
            } else {
                dot.style.display = 'none';
            }
        });
    };

    const handleMouseWheel = (event) => {
        const delta = Math.sign(event.deltaY);
        let nextSider = activeSider;

        if (delta > 0) {
            nextSider = Math.min(activeSider + 1, siderRefs.current.length);
        } else {
            nextSider = Math.max(activeSider - 1, 1);
        }

        if (nextSider !== previousSider) {
            setActiveSider(nextSider);
            siderRefs.current[nextSider - 1]?.scrollIntoView({ behavior: 'auto', block: 'nearest' });
            setPreviousSider(nextSider);
            handleDotVisibility();
        }
    };

    useEffect(() => {
        window.addEventListener('wheel', handleMouseWheel);

        return () => {
            window.removeEventListener('wheel', handleMouseWheel);
        };
        // eslint-disable-next-line
    }, [activeSider, previousSider]);

    const handleDotClick = (siderNumber) => {
        const totalSiders = siderRefs.current.length;
        const nextSider = Math.min(Math.max(1, siderNumber), totalSiders);
        setActiveSider(nextSider);
        siderRefs.current[nextSider - 1]?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        setPreviousSider(nextSider);
    };

    const handleScroll = () => {
        siderRefs.current.forEach((sider, index) => {
            if (sider) {
                const rect = sider.getBoundingClientRect();
                const siderCenter = rect.top + rect.height / 2;

                if (index !== 0 && siderCenter >= 0 && siderCenter <= window.innerHeight) {
                    setShowHeader(true);
                } else if (index === 0 && siderCenter >= 0 && siderCenter <= window.innerHeight) {
                    setShowHeader(false);
                }

                if (index + 1 === 4 && activeSider === 4) {
                    sider.scrollIntoView({ behavior: 'smooth', block: 'start' });
                } else if (siderCenter >= 0 && siderCenter <= window.innerHeight) {
                    setActiveSider(index + 1);
                }
            }
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
        // eslint-disable-next-line
    }, []);

    const handleOuterBtnClick = () => {
        siderRefs.current[1]?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        setActiveSider(2);
        setPreviousSider(2);
        handleDotVisibility();
    };

    return (
        <div className={cx('wrapper')}>
            <div className={cx('container')}>
                {showHeader && (
                    <div className={cx('Sticky-Header')}>
                        <div className={cx('header-nav_logo')} onClick={() => handleDotClick(1)}>
                            <img src={images.logo} alt="logo-header" />
                        </div>
                        <div className={cx('list-nav')}>
                            <div className={cx('list-btn', { active: activeSider === 2 })} onClick={() => handleDotClick(2)}>Quảng cáo hiển thị</div>
                            <div className={cx('list-btn', { active: activeSider === 3 })} onClick={() => handleDotClick(3)}>PR</div>
                            <div className={cx('list-btn', { active: activeSider === 4 })} onClick={() => handleDotClick(4)}>Mạng xã hội</div>
                            <div className={cx('list-btn', { active: activeSider === 5 })} onClick={() => handleDotClick(5)}>Sự kiện</div>
                        </div>
                    </div>
                )}
                <div className={cx('sider1')} ref={(el) => siderRefs.current[0] = el}>
                    <div className={cx('header')}>
                        <Link to={config.routes.home} className={cx('header-nav_logo')}>
                            <img src={images.logo} alt="logo-header" />
                        </Link>
                    </div>
                    <div className={cx('text-sider')}>
                        <h1>BẢNG GIÁ QUẢNG CÁO</h1>
                        <div className={cx('box-contact')}>
                            <a href="mailto:v6sport.news@gmail.com">Email: v6sport.news@gmail.com</a>
                            <a href="tel:+639954821880">Hotline: +639954821880</a>
                        </div>
                    </div>
                    <div className={cx('list-nav')}>
                        <div className={cx('list-btn')} onClick={() => handleDotClick(2)}>Quảng cáo hiển thị</div>
                        <div className={cx('list-btn')} onClick={() => handleDotClick(3)}>PR</div>
                        <div className={cx('list-btn')} onClick={() => handleDotClick(4)}>Mạng xã hội</div>
                        <div className={cx('list-btn')} onClick={() => handleDotClick(5)}>Sự kiện</div>
                    </div>
                    <div className={cx('button-sider')}>
                        <a href="https://www.facebook.com/V6sport.news" target="_blank" rel="noopener noreferrer">
                            LIÊN HỆ NHẬN BÁO GIÁ
                        </a>
                    </div>
                    <div className={cx('outer-btn')} >
                        Cuộn xuống để tham khảo
                    </div>
                    <div className={cx('outer-btn')} onClick={handleOuterBtnClick}>
                        <FontAwesomeIcon className={cx('icon')} icon={faChevronDown} />
                    </div>
                </div>
                <div className={cx('sider2')} ref={(el) => siderRefs.current[1] = el}>
                    <div className={cx('sider-content')}>
                        <div className={cx('sider-txt')}>
                            <h2 className={cx('quang_cao')}>QUẢNG CÁO</h2>
                            <h2 className={cx('hien_thi')}>HIỂN THỊ</h2>
                            <div className={cx('outer-view')}>XEM BÁO GIÁ
                                <div className={cx('btn-view')}>
                                    <a href="https://www.facebook.com/V6sport.news" target="_blank" rel="noopener noreferrer">
                                        CHI TIẾT
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className={cx('sider-view')}>
                            <div className={cx('view')}>
                                <CustomSlider data={images.slider} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={cx('sider2')} ref={(el) => siderRefs.current[1] = el}>
                    <div className={cx('sider-content')}>
                        <div className={cx('sider-txt')}>
                            <h2 className={cx('quang_cao')}>QUẢNG CÁO</h2>
                            <h2 className={cx('hien_thi')}>HIỂN THỊ</h2>
                            <div className={cx('outer-view')}>XEM BÁO GIÁ
                                <div className={cx('btn-view')}>
                                    <a href="https://www.facebook.com/V6sport.news" target="_blank" rel="noopener noreferrer">
                                        CHI TIẾT
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className={cx('sider-view')}>
                            <div className={cx('view')}>
                                <CustomSlider data={images.slider} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={cx('sider3')} ref={(el) => siderRefs.current[2] = el}>
                    <div className={cx('sider-content')}>
                        <div className={cx('sider-txt')}>
                            <h2 className={cx('quang_cao')}>PR</h2>
                            <div className={cx('outer-view')}>XEM BÁO GIÁ
                                <div className={cx('btn-view')}>
                                    <a href="https://www.facebook.com/V6sport.news" target="_blank" rel="noopener noreferrer">
                                        CHI TIẾT
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className={cx('sider-view')}>
                            <div className={cx('view')}>
                                <CustomSlider2 data={images.slider2} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={cx('sider4')} ref={(el) => siderRefs.current[3] = el}>
                    <div className={cx('sider-content')}>
                        <div className={cx('sider-txt')}>
                            <h2 className={cx('quang_cao')}>MẠNG XÃ HỘI</h2>
                            <div className={cx('outer-view')}>XEM BÁO GIÁ
                                <div className={cx('btn-view')}>
                                    <a href="https://www.facebook.com/V6sport.news" target="_blank" rel="noopener noreferrer">
                                        LIÊN HỆ
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className={cx('sider-view')}>
                            <div className={cx('view')}>
                                <CustomSlider3 data={images.slider3} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={cx('sider5')} ref={(el) => siderRefs.current[4] = el}>
                    <div className={cx('sider-content')}>
                        <div className={cx('sider-txt')}>
                            <h2 className={cx('quang_cao')}>SỰ KIỆN</h2>
                            <div className={cx('outer-view')}>
                                <div className={cx('btn-view')}>
                                    <a href="https://www.facebook.com/V6sport.news" target="_blank" rel="noopener noreferrer">
                                        LIÊN HỆ
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className={cx('sider-view')}>
                            <div className={cx('view')}>
                                <CustomSlider4 data={images.slider4} />
                            </div>
                        </div>
                    </div>
                    <div className={cx('wrapper2')} onDragStart={handleDragStart} onDragOver={handleDragOver} onDrop={handleDrop}>
                        <div className={cx('footer')} onDragStart={handleDragStart} onDragOver={handleDragOver} onDrop={handleDrop}>
                            <div
                                className={cx('footer-row')}
                                onDragStart={handleDragStart}
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                            >
                                <div
                                    className={cx('footer-item')}
                                    onDragStart={handleDragStart}
                                    onDragOver={handleDragOver}
                                    onDrop={handleDrop}
                                >
                                    <img className={cx('logo')} src={images.logo} alt="V6Sport" />
                                    <p className={cx('content')}>
                                        <strong>V6sport</strong> -  là một trang web tổng hợp tin tức, video thể thao nóng hổi Việt Nam và thế giới, 
                                        lịch trình thi đấu, tỷ số, bảng xếp hạng ...vô vàn giải đấu thể thao hấp dẫn.Ngoài ra <strong>V6sport</strong> 
                                        còn chia sẻ các bài phân tích, nhận định từ các chuyên gia hàng đầu về thể thao. Đồng thời V6sport còn là nơi giao lưu, 
                                        trao đổi ý kiến và phát triển mạnh mẽ cộng đồng yêu thể thao.
                                    </p>
                                </div>
                                <div
                                    className={cx('footer-item')}
                                    onDragStart={handleDragStart}
                                    onDragOver={handleDragOver}
                                    onDrop={handleDrop}
                                >
                                    <div
                                        className={cx('footer-add')}
                                        onDragStart={handleDragStart}
                                        onDragOver={handleDragOver}
                                        onDrop={handleDrop}
                                    >
                                        <p className={cx('contact-info')}><strong>TRỤ SỞ HÀ NỘI</strong></p>
                                        <br />
                                        <p className={cx('contact-info')}><FontAwesomeIcon className={cx('icon')} icon={faLocationDot} /><strong> Địa Chỉ: </strong> 120 Cầu Giấy, Quận Cầu Giấy, Hà Nội, Việt Nam</p>
                                        <p className={cx('contact-info')}><FontAwesomeIcon className={cx('icon')} icon={faEnvelope} /><strong> Email: </strong> v6sport.news@gmail.com</p>
                                        <p className={cx('contact-info')}><FontAwesomeIcon className={cx('icon')} icon={faPhone} /><strong> Điện Thoại: </strong> +639954821880</p>
                                        <p className={cx('contact-info')}><FontAwesomeIcon className={cx('icon')} icon={faHouseSignal} /><strong> Website: </strong> <a href="https://www.V6Sport.com">V6Sport.com</a></p>
                                    </div>    
                                </div>
                            </div>
                        </div>
                        <p className={cx('copyright')}>
                            Copyright 2023 ©
                            <strong>
                                <Link to="/" rel="noopener">
                                    {' '}
                                    V6sport
                                </Link>
                            </strong>
                            . All rights reserved. Philippine government FCLRC game licensing certification.
                        </p>
                    </div>
                </div>
            </div>
            <div className={cx('dots')}>
                <div className={cx('dot', { active: activeSider === 1 })} onClick={() => handleDotClick(1)}></div>
                <div className={cx('dot', { active: activeSider === 2 })} onClick={() => handleDotClick(2)}></div>
                <div className={cx('dot', { active: activeSider === 3 })} onClick={() => handleDotClick(3)}></div>
                <div className={cx('dot', { active: activeSider === 4 })} onClick={() => handleDotClick(4)}></div>
                <div className={cx('dot', { active: activeSider === 5 })} onClick={() => handleDotClick(5)}></div>
            </div>
        </div>
    );
}

export default Home;
