import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './CustomSlider.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const cx = classNames.bind(styles);

function CustomSlider4({ data, className }) {
    const [image, setImage] = useState(0);
    const [sliderRef, setSliderRef] = useState(null);

    const handlePrev = () => {
        if (sliderRef) {
            sliderRef.slickPrev();
        }
    };

    const handleNext = () => {
        if (sliderRef) {
            sliderRef.slickNext();
        }
    };

    useEffect(() => {
        if (sliderRef) {
            sliderRef.slickGoTo(image);
        }
    }, [sliderRef, image]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        fade: false, // Thay đổi fade thành false
        cssEase: 'linear', // Loại bỏ fade, nếu không sẽ xảy ra hiệu ứng kỳ lạ
        beforeChange: (_, next) => {
            setImage(next);
        },
        prevArrow: <></>, // Loại bỏ prevArrow mặc định
        nextArrow: <></>, // Loại bỏ nextArrow mặc định
    };

    return (
        <div className={cx('wrapper', { [className]: className })}>
            <Slider ref={(ref) => setSliderRef(ref)} {...settings}>
                {data.map((img, index) => (
                    <div key={index} className={cx('slide')}>
                        <img className={cx('img')} src={img} alt={`slide-${index}`} />
                    </div>
                ))}
            </Slider>
            <div className={cx('prev')} onClick={handlePrev}>
                <FontAwesomeIcon className={cx('icon')}  icon={faChevronLeft} />
            </div>
            <div className={cx('next')} onClick={handleNext}>
                <FontAwesomeIcon className={cx('icon')} icon={faChevronRight} />
            </div>
            <div className={cx('dots-container')}>
                {data.map((_, index) => {
                    if (index === image) {
                        return (
                            <span
                                key={index}
                                className={cx('dot', { 'active-dot': index === image })}
                                onClick={() => setImage(index)}
                            >
                                {index === 0 ? 'Cộng đồng “V6Sport”' : 
                                index === 1 ? 'Chiến dịch tình nguyện Của “V6Sport”' : 
                                index === 2 ? 'Cộng đồng V6Sport Tại “Singapore” ' : 
                                index === 3 ? 'Giải bóng đá “V6Sport” ' : ''}
                            </span>
                        );
                    } else {
                        return null;
                    }
                })}
            </div>
        </div>
    );
}

export default CustomSlider4;
