const images = {
    logo: require('~/assets/images/Logo.png'),
    slider: [
        require('~/assets/images/Display_Ads_Home.png'),
        require('~/assets/images/Display_Ads_Cate.png'),
        require('~/assets/images/Display_Ads_Detail.png'),
    ],
    slider2: [
        require('~/assets/images/PR_Home.png'),
        require('~/assets/images/PR_Cate.png'),
        require('~/assets/images/PR_Cate_1.png'),
    ],
    slider3: [
        require('~/assets/images/social-cac-hinh-thuc.jpg'),
        require('~/assets/images/social-ghim-post.jpg'),
    ],
    slider4: [
        require('~/assets/images/1.jpg'),
        require('~/assets/images/2.jpg'),
        require('~/assets/images/3.jpg'),
        require('~/assets/images/4.jpg'),
    ],

};

export default images;
